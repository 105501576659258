import request from "@/axios";
let url = "https://caq.etuic.com/eval-api";
// let url = "http://192.168.0.62:8090/eval-api"

export default {
  //获取设备基础信息
  getDeviceByMacAddr (params) {
    return request.get(url + "/open/nqueue/device/getByMac?macAddr=" + params);
  },
  getRandomMac () {
    return request.get(url + "/open/nqueue/getRandomMac");
  },
  //登录
  login (params) {
    return request.postJson(url + "/open/nqueue/eval/login", params);
  },
  //退出登录
  logout (params) {
    return request.postJson(url + "/nqueue/eval/logout/" + params);
  },
  //根据token获得用户信息
  getUserInfo (params) {
    return request.post(url + "/nqueue/eval/worker/info", params);
  },
  //叫号器 - 代办列表
  getCallList (params) {
    return request.get(url + "/nqueue/call/todo?callMacAddr=" + params);
  },
  //叫号器 - 已办列表
  getCallListFinish (params) {
    return request.get(url + "/nqueue/call/finish?callMacAddr=" + params);
  },
  //叫号器 - 叫号
  callTicket (params) {
    return request.get(url + "/nqueue/call/callTicket?callMacAddr=" + params);
  },
  //叫号器 - 评价
  onEval (params) {
    return request.get(url + "/nqueue/call/appraise?callMacAddr=" + params);
  },
  //叫号器 - 跳过此号
  skipTicket (params) {
    return request.get(url + "/nqueue/call/skipTicket?callMacAddr=" + params);
  },
  //叫号器 - 暂停服务
  pauseService (params) {
    return request.get(url + "/nqueue/call/pause?callMacAddr=" + params);
  },
  //叫号器 - 欢迎光临
  welcome (params) {
    return request.get(url + "/nqueue/call/welcome?callMacAddr=" + params);
  },
  //叫号器 - 重复呼叫
  repeatCall (params) {
    return request.get(url + "/nqueue/call/repeatCall?callMacAddr=" + params);
  },
  //叫号器 - 当前正在办理的业务
  getInBusinessRecord (params) {
    return request.get(url + "/nqueue/call/getInBusinessRecord?callMacAddr=" + params);
  },
  //叫号器 - 设备密码校验
  validatePassword (params1,params2) {
    return request.get(url + "/open/nqueue/device/validatePassword?macAddr=" + params1+'&setPassword='+params2);
  },
  //排队屏-列表
  getQueueScreenList (params) {
    return request.get(url + "/open/nqueue/call/todo/" + params);
  }

}
