import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";

Vue.config.productionTip = false

// vant
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);

import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
let options = {
  fullscreenEl: false,
  closeEl: true, // 控制是否显示右上角关闭按钮
  tapToClose: true, // 点击滑动区域应关闭图库
  shareEl: false, // 控制是否显示分享按钮
  zoomEl: false, // 控制是否显示放大缩小按钮
  counterEl: false, // 控制是否显示左上角图片数量按钮
  arrowEl: false, // 控制如图的左右箭头（pc浏览器模拟手机时）
  tapToToggleControls: false, // 点击应切换控件的可见性
  clickToCloseNonZoomable: true // 点击图片应关闭图库，仅当图像小于视口的大小时
}
Vue.use(preview, options)
Vue.use(preview)

//element ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// import VConsole from 'vconsole';
// const vConsole = new VConsole();
// Vue.use(vConsole);


import VueAwesomeSwiper from 'vue-awesome-swiper'
//require styles
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper, /* { default global options } */)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
